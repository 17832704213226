@import "./custom-variables.scss";
@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/Oswald-VariableFont_wght.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/roboto.ttf') format('ttf');
}

body {
  font-family:'Instagram Sans Condensed', sans-serif !important;
}
ion-content {
  font-family:'Instagram Sans Condensed', sans-serif !important;
  --ion-background-color: #ffffff;
}
.plusval-font{
  font-family:'Instagram Sans Condensed', sans-serif !important;
}
.background-filler {
  z-index: -1;
  background-color: $base-color;
  height: 150px;
  width: 100%;
  position: absolute;
  top: 0px;
  overflow: hidden;
}
.white-shadow-container {
  background: white;
  box-shadow: 0 30px 60px 0 rgba(138,149,158,0.20);
  border-radius: 5px;
  .title {
    font-weight: bold;
  }
  .subtitle {
    font-size: .9em;
    color: gray;
  }
}
.primary-shadow-container {
  background-color: $base-color;
  box-shadow: 0 30px 60px 0 rgba(138,149,158,0.20);
  border-radius: 5px;
}
.title1 {
  font-size: 3rem;
}
.title2 {
  font-size: 2rem;
}
.title3 {
  font-size: 1.5rem;
}
.subtitle1 {
  font-size: .9em;
  color: gray;
}
.subtitle2 {
  font-size: .8em;
  color: gray;
}
.subtitl3 {
  font-size: .7em;
  color: gray;
}
.circle-container-with-icon {
  background: #F1EFFC;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  ion-icon {
    color: $base-color;
    font-size: 1.5rem;
    margin-top: .5rem;
  }
}
.header-internal-page {
  display: flex;
  background-color: $base-color;
  padding: 30px 15px;
  color: white;
  .title-container {
    flex: 7;
  }
  .action-container {
    flex: 3;
    text-align: right;
    padding-top: 8px;
    ion-icon {
      margin-left: 10px;
      font-size: 1.5em;
    }
  }
}
.m-t-0 {
  margin-top: 0px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.p-b-0 {
  padding-bottom: 0px !important;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.img-fluid {
  width: 100%;
}
.oswald-font {
  font-family:'Oswald', sans-serif !important;
}
.roboto-font {
  font-family: 'Roboto';
}
.image-background-header {
  height: 250px;
  background-image: url(https://esaucamelo.com.mx/wp-content/uploads/2019/06/08-SANTORINI-ACCESO-PRIVADA.png);
  background-size: cover;
  background-position: center;
  filter: blur(2px);
  position: absolute;
  width: 100%;
  z-index: -1;
}
.action-container-header {
  text-align: right;
  display: block;
  ion-icon {
    float: right;
    color: white;
    font-size: 1.5em;
    padding-left: 10px;
  }
}
.display-flex {
  display: flex;
}

.scroll-horizontal {
  overflow: auto;
  white-space: nowrap;
  padding: 15px 0 20px 0;
}
.base-background-color {
  background-color: $base-color !important;
}
.month-packer-item button {
  background-color: #757575 !important;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-8 {
  flex: 8;
}
.flex-9 {
  flex: 9;
}
.flex-10 {
  flex: 10;
}
.flex-11 {
  flex: 11;
}
.flex-12 {
  flex: 12;
}
.avatar {
  background-size: cover;
  background-position: center;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  display: inline-block;
  margin: auto;
  top: -35px;
}
.border-gray-bottom {
  border-bottom: 1px solid #ededed;
}
.rating-stars-container {
  padding-top: 10px;
  ion-icon {
    font-size: 1.75em;
    color: rgb(255, 187, 0);
  }
}
.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tab-container {
  color: white;
}
.tab-element {
  font-family: 'Oswald';
  text-align: center;
  padding: 10px;
}
.tab-element.active {
  border-top: 1px solid white;
}
.income-outcome {
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  .concept {
    flex: 4;
    .date {
      font-size: .8em;
      color: rgb(94, 94, 94);
    }
    .text {
      font-family: 'Oswald';
    }
  }
  .green {
    color: rgb(83, 130, 8);
  }
  .red {
    color: rgb(130, 8, 8);
  }
  .amount {
    text-align: right;
    font-size: 1.2em;
    font-weight: bold;
    flex: 3;
  }
}
.white-text {
  color: white;
}
.giant-title {
  font-size: 2.6em;
}

.empty-data{
  width: 200px;
}

ion-toolbar {
  --background: #5251fa !important;
  color: white;
  ion-icon {
    color: white;
  }
  ion-menu-button {
    color: white !important;
  }
}
.avatar-container {
  flex: 2;
  .avatar {
    background-size: cover;
    background-position: 0;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    border: 3px solid $accent-color;
    background-color: $accent-color;
    .initials {
      text-align: center;
      font-size: 1.6em;
      margin: auto;
      margin-top: 10px;
      color: white;
    }
  }
}
.to-upper {
  text-transform: uppercase;
}
.one-line-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 310px;
}
.container-flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-8 {
  flex: 8;
}
.flex-9 {
  flex: 9;
}
.flex-10 {
  flex: 10;
}
.featured {
  height: 420px;
  background-image: url('../assets/img/banner/buldingiBackground.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  .text-container {
    text-align: right;
      padding: 15px;
      padding-top: 60px;
      position: absolute;
      top: 0;
      width: 100%;
      background: rgb(0,0,0);
      background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 65%, rgba(0,0,0,0.75) 100%);
      height: 40%;
      color: white;
      .title {
          font-size: 1.1em;
      }
      .description {
          display: block;
          .smaller-cropped {
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: #ededed;
              font-size: .95em;
          }
      }
  }
}
.center-text {
  text-align: center;
}