/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/style.scss";


/* Align the items of action sheets*/

.left-align-buttons .action-sheet-button-inner.sc-ion-action-sheet-ios {
    justify-content: flex-start;
}

//Icon status
.status-icon {
    height: 20px;
    width: 20px;
    margin-left: 3px;
    margin-top: 1px;
    position: absolute;
}

.can-go-back {
  ion-menu-button {
    display: none;
  }
}

.empty-text{
  font-size: 16px;
}

.empty-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.custom-bottom-sheet.expanded {
  transform: translateY(0);
}

.custom-bottom-sheet .content {
  padding: 16px;
  /* Añade cualquier otro estilo que desees */
}

.background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  pointer-events: auto;
}

.custom-bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 50vh;
  overflow-y: auto;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
}

.custom-bottom-sheet.expanded {
  transform: translateY(0);
}